import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@/environments/environment';
import { Title } from '@angular/platform-browser';
import axios from 'axios';
import { backendServer } from 'src/environment';
import { UtilsService } from './utils.service';

const httpOptions = {
  headers: new HttpHeaders({
    Accept: 'application/json',
    Authorization: 'Basic Y2FyZWVybmFrc2hhOjMzNjRzY3A=',
  }),
};

const hosturl = environment.hosturl;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public socialdata = {
    name: '',
    email: '',
    phone: '',
  };

  private _registerUrl = hosturl + '/api/register';
  private savebasic = hosturl + '/api/updateuser/basic';
  private savepersonal = hosturl + '/api/updateuser/personal';
  private saveeducational = hosturl + '/api/updateuser/educational';
  private saveprofessional = hosturl + '/api/updateuser/professional';
  private saveinterest = hosturl + '/api/updateuser/interest';
  private savelifestyle = hosturl + '/api/updateuser/lifestyle';
  private formdetails = hosturl + '/api/formdetails';
  private getfiles = hosturl + '/api/getfiles';
  private mailUrl = hosturl + '/api/sendMail';
  private createPaymentOrder = hosturl + '/api/razorPayOrder';
  private sendContactUrl = hosturl + '/api/sendContact';
  private sendRequestUrl = hosturl + '/api/sendRequest';
  private registerUrlSocial = hosturl + '/api/registerUserSocial';
  private appoUrl = hosturl + '/api/sendAppoData';
  private sendEmailUrl = hosturl + '/api/sendEmailUrl';
  private reportUrl = hosturl + '/api/report';
  private infoUrl = hosturl + '/api/getuserinfo';
  private knowUrl = backendServer + '/api/know';
  private registerUrl = hosturl + '/api/register';
  private registerUrlRefer = hosturl + '/api/register/refer';
  private _recoverpwd = backendServer + '' + '/api/recoverpwd';
  private _Personality = backendServer + '/api/getuser/assessment/personality';
  private _resetpwd = '';
  private _registerSocialUser = `${backendServer}/api/registerUserSocial`;

  constructor(
    private http: HttpClient,
    private _router: Router,
    // public afAuth: AngularFireAuth,
    private titleService: Title,
    private utilsService: UtilsService
  ) {}

  async sendLoginDump(email: string, type: string) {
    let data = {
      Email: email,
      Type: type,
      Date: new Date().toLocaleDateString(),
    };

    let data_dump = await axios.post(
      'https://dashboard.careernaksha.com/login-data',
      data
    );
  }

  registerSocial(data: any) {
    return this.http.post<any>(this._registerSocialUser, data);
  }
  addRefer(data: any) {
    return this.http.post<any>(backendServer + '/api/addRefer', data);
  }

  getReferPoints(data: any) {
    return this.http.post<any>(backendServer + '/api/getRefer', data);
  }

  returnEmail(data: any) {
    return data.email;
  }

  getPersonalityAssesment(data: any) {
    return this.http.post<any>(this._Personality, data);
  }

  getInterestAssesment(data: any) {
    return this.http.post<any>(
      backendServer + '/api/getuser/assessment/interest',
      data
    );
  }

  getAiAssessment(data: any) {
    return this.http.post<any>(
      backendServer + '/api/getuser/assessment/ai',
      data
    );
  }

  getJobAssessment(data: any) {
    return this.http.post<any>(
      backendServer + '/api/getuser/assessment/job',
      data
    );
  }

  getMathematicsAssessment(data: any) {
    return this.http.post<any>(
      backendServer + '/api/getuser/assessment/maths',
      data
    );
  }

  setPersonality(data: any) {
    return this.http.post<any>(
      `${backendServer}/api/updateuser/assessment/personality`,
      data
    );
  }

  setAI(data: any) {
    return this.http.post<any>(
      backendServer + '/api/updateuser/assessment/ai',
      data
    );
  }

  setInterestTest(data: any) {
    return this.http.post<any>(
      backendServer + '/api/updateuser/assessment/interest',
      data
    );
  }

  sendAIReport(data: any) {
    return this.http.post<any>(backendServer + '/api/report/ai', data);
  }

  setJobTest(data: any) {
    return this.http.post<any>(
      backendServer + '/api/updateuser/assessment/job',
      data
    );
  }

  setMathematicsTest(data: any) {
    return this.http.post<any>(
      backendServer + '/api/updateuser/assessment/maths',
      data
    );
  }

  sendAppointmentMail(data: any) {
    return this.http.post<any>(
      backendServer + '/api/sendAppointmentMail',
      data
    );
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
    window.scroll(0, 0);
  }

  sendReport(data: any) {
    return this.http.post<any>(this.reportUrl, data);
  }

  sendMail(data: any) {
    return this.http.post<any>(this.mailUrl, data);
  }

  getInfo(data: any) {
    return this.http.post<any>(this.infoUrl, data);
  }

  createPayment(data: any) {
    return this.http.post<any>(this.createPaymentOrder, data);
  }

  sendAppoData(data: any) {
    return this.http.post<any>(this.appoUrl, data);
  }

  async sendContactToDashboard(data: any) {
    let contact_data = {
      Email: data.email,
      Phone: data.phone,
      InterestField: data.interestedIn,
      Message: data.message,
      Date: new Date().toLocaleDateString(),
    };

    let data_strapi = await axios.post(
      'https://dashboard.careernaksha.com/contact-forms',
      contact_data
    );
    let email = await axios.post(this.sendContactUrl, contact_data);
  }

  sendRequest(data: any) {
    return this.http.post<any>(this.sendRequestUrl, data);
  }

  sendEmail(data: any) {
    return this.http.post<any>(this.sendEmailUrl, data);
  }

  knowData(data: any) {
    return this.http.post<any>(this.knowUrl, data);
  }

  saveBasic(data: any) {
    return this.http.post<any>(this.savebasic, data);
  }

  savePersonal(data: any) {
    return this.http.post<any>(this.savepersonal, data);
  }

  saveEducational(data: any) {
    return this.http.post<any>(this.saveeducational, data);
  }

  saveProfessional(data: any) {
    return this.http.post<any>(this.saveprofessional, data);
  }

  saveInterest(data: any) {
    return this.http.post<any>(this.saveinterest, data);
  }

  saveLifestyle(data: any) {
    return this.http.post<any>(this.savelifestyle, data);
  }

  formDetails(data: any) {
    return this.http.post<any>(this.formdetails, data);
  }

  getFiles(data: any) {
    return this.http.post<any>(this.getfiles, data);
  }

  public loginUser(user: any) {
    return this.http.post<any>(backendServer + '/api/login', user);
  }

  getQuestionJson() {
    return this.http.get<any>('./questions.json');
  }

  getUserInfo(data: any) {
    return this.http.post<any>(backendServer + '/api/getuserinfo', data);
  }

  registerUser(user: any) {
    return this.http.post<any>(this._registerUrl, user);
  }
  registerUserRefer(user: any) {
    return this.http.post<any>(this.registerUrlRefer, user);
  }

  recoverpwd(user: any) {
    return this.http.post<any>(this._recoverpwd, user);
  }

  pushTestToDb(data: any) {
    return this.http.post<any>(
      'http://localhost:1337/psychometric-users',
      data,
      httpOptions
    );
  }

  resetpwd(user: any) {
    this._resetpwd = hosturl + '/api' + localStorage.getItem('link');
    console.log(' resetting password --_resetpwd link -->');
    console.log(this._resetpwd);
    return this.http.put<any>(this._resetpwd, user);
  }

  registerUserSocial(user: any) {
    return this.http.post<any>(this.registerUrlSocial, user);
  }

  getResult(answerString: string) {
    let data = {
      answerString,
    };

    return this.http.post<any>(
      `https://api.careernaksha.com/api/interestProfiler`,
      data
    );
  }

  getCareers() {
    if (this.utilsService.isThisBrowser()) {
      let answerString = localStorage.getItem('interestAnswerString');
      let data = {
        answerString,
      };

      return this.http.post<any>(
        `https://api.careernaksha.com/assessment/getJobZone`,
        data
      );
    }
    return null;
  }
  getSkills() {
    if (this.utilsService.isThisBrowser()) {
      let answerString = localStorage.getItem('interestAnswerString');
      let data = {
        answerString,
      };

      return this.http.post<any>(
        `https://api.careernaksha.com/assessment/skills`,
        data
      );
    }
    return null;
  }

  register(user: any) {
    return this.http.post<any>(this.registerUrl, user);
  }

  loggedIn() {
    if (this.utilsService.isThisBrowser()) {
      return (
        !!localStorage.getItem('token') || !!localStorage.getItem('user_id')
      );
    }
    return false;
  }

  isAdmin() {
    if (this.utilsService.isThisBrowser() && localStorage.getItem('user_id')) {
      if (
        localStorage.getItem('user_id') == '668b5ac1625cf0050152c15d' ||
        localStorage.getItem('user_id') == '62908e722b2c6a4345a309b2' ||
        localStorage.getItem('user_id') == '65dc5182bb27216490f8ee2e'
      ) {
        return true;
      }
    }
    return false;
  }

  reportLoggedIn() {
    if (this.utilsService.isThisBrowser()) {
      return !!localStorage.getItem('rplg');
    }
    return false;
  }

  logoutUser() {
    if (this.utilsService.isThisBrowser()) {
      localStorage.removeItem('token');
      localStorage.removeItem('social-name');
      localStorage.removeItem('user_id');
    }
    this._router.navigate(['/']);
  }
}
